<template>
  <div class="container">
    <img
      class="background-image"
      alt="background-img"
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef1f-6e01-3186-34bd-e6a3e6412b03?x-oss-process=style/origin"
    />
    <!-- <div
      class="cover"
      :style="{
        backgroundImage:
          'url(' + campaign.post?.images[0]?.image_info.thumbnail_url + ')',
      }"
    /> -->
    <div class="main">
      <div class="item">
        <img
          class="avatar"
          :src="campaign.post.author.avatar_url"
          alt="avatar"
        />
        <div class="info">
          <div class="info_top">{{ campaign.post.author.nickname }}</div>
          <div class="info_bottom">发起比赛</div>
        </div>
      </div>
      <div class="item">
        <div class="avatar-group">
          <template v-if="!!squads.length">
            <img
              class="avatar"
              :src="squad.avatar_url"
              alt="avatar"
              v-for="squad of squads"
              :key="squad.id"
            />
          </template>
          <img
            v-else
            class="default"
            alt="avatar"
            src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef1f-c27f-5da2-c477-080814185191?x-oss-process=style/origin"
          />
        </div>
        <div v-if="!!squads.length" class="info">
          <div class="info_top">{{squadsCount}}支战队</div>
          <div class="info_bottom">已应战</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "leagues",
  data() {
    return {
      campaignId: "",
      campaign: {},
      squads: [],
      squadsCount: 0,
    };
  },
  created() {
    let { campaignId } = this.$route.query;
    this.getCampaign((this.campaignId = campaignId));
    this.getJoinSquads(campaignId);
  },
  methods: {
    /** 获取活动详情 */
    getCampaign(campaignId) {
      $fetch
        .post("GetSharedCampaign", {
          postId: "",
          campaignId,
        })
        .then((campaign) => {
          this.campaign = campaign;
        });
    },
    /** 获取已参赛的战队列表 */
    getJoinSquads(campaignId) {
      $fetch
        .post("ListCompetitionSquads", {
          campaignId,
        })
        .then((res) => {
          this.squadsCount = res.squads.length;
          this.squads = res.squads.slice(0, 3);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  @include column(flex-start, center);
  position: relative;
  width: 100%;
  height: 80vw;
  background-color: #ccc;

  > .background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .cover {
    position: absolute;
    left: 50%;
    top: px2rem(20 * $multipleCount);
    transform: translateX(-50%);
    width: px2rem(368 * $multipleCount);
    height: px2rem(212 * $multipleCount);
    border: px2rem(4 * $multipleCount) solid #000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: px2rem(8 * $multipleCount);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: px2rem(136 * $multipleCount);
      transform: translateX(-50%);
      width: px2rem(240 * $multipleCount);
      height: px2rem(96 * $multipleCount);
      background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef23-354c-7988-d05b-0e0012a24061?x-oss-process=style/origin")
        no-repeat center center / contain;
    }
  }

  > .main {
    $avatarWith: px2rem(48 * $multipleCount);

    @include row(space-between, center);
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100vw - px2rem(16 * $multipleCount * 2));
    height: $avatarWith;
    margin: px2rem(16 * $multipleCount);
    overflow: hidden;

    > .item {
      $marginAside: px2rem(8 * $multipleCount);
      @include row;
      flex: 1;
      height: 100%;
      overflow: hidden;

      > .avatar {
        width: $avatarWith;
        height: $avatarWith;
        border-radius: 50%;
        margin-right: $marginAside;
      }

      > .avatar-group {
        @include row;
        min-width: $avatarWith;
        height: $avatarWith;
        margin-left: $marginAside;

        > .default {
          width: px2rem(96 * $multipleCount);
          height: calc(100% - px2rem(2 * $multipleCount));
          object-fit: contain;
        }

        > .avatar {
          width: $avatarWith;
          height: $avatarWith;
          border-radius: 50%;
          
          &:not(:last-child) {
            margin-right: calc($avatarWith / -2);
          }
        }
      }

      > .info {
        @include column(space-between, flex-start);
        flex: 1;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;

        > [class^="info_"] {
          $textHeight: px2rem(20 * $multipleCount);

          @include ellipsis;
          width: 100%;
          height: $textHeight;
          font-size: $textHeight;
          line-height: $textHeight;
          font-family: "Alibaba PuHuiTi Bold", Alibaba PuHuiTi;
          font-weight: bold;
          color: #ffffff;
        }
      }

      &:nth-child(2) {
        flex-direction: row-reverse;

        > .avatar-group {
          margin-left: $marginAside;
          margin-right: 0;
        }

        > .info [class^="info_"] {
          text-align: right;
        }
      }
    }
  }
}
</style>
